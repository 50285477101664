<template>
    <div class="jh-container">
        <div class="jh-ui-header">
            <h1>2차상담 관리</h1>
            <div class="is-right">
            </div>
        </div>

        <!--조회-->
        <div class="jh-search-form">
            <table>
                <colgroup>
                    <col width="55px">
                    <col width="110px">
                    <col width="70px">
                    <col width="110px">
                    <col width="80px">
                    <col width="120px">
                    <col width="80px">
                    <col width="120px">
                    <col width="80px">
                    <col width="120px">
                    <col>
                </colgroup>
                <tr>
                    <th><label>접수일</label></th>
                    <td colspan="3">
                    <div class="jh-cols">                        
                        <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                            maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                        </template>
                        <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="changeDate('start')"></v-date-picker>
                        </v-menu>
                        <span class="jh-unit">~</span>                       
                        <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                            maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                        </template>
                        <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="changeDate('end')"></v-date-picker>
                        </v-menu>
                    </div>
                    </td>
                    <th><label>고객번호</label></th>
                    <td><input type="text" class="jh-form" v-model="CUST_NO" @keydown.enter="selectbutton"></td>
                    <th><label>고객명</label></th>
                    <td><input type="text" class="jh-form" v-model="CUST_NM" @keydown.enter="selectbutton"></td>
                    <th><label>계좌번호</label></th>
                    <td><input type="text" class="jh-form" v-model="ACCOUNT" @keydown.enter="selectbutton" oninput="this.value = this.value.replace(/\-/g, '');"></td>
                </tr>
                <tr>
                    <th><label>접수직원</label></th>
                    <td><input type="text" class="jh-form" v-model="RCPT_MAN" @keydown.enter="selectbutton"></td>
                    <th><label>배당직원</label></th>
                    <td><input type="text" class="jh-form" v-model="CSLT_MAN" @keydown.enter="selectbutton"></td>
                    <th><label>처리직원</label></th>
                    <td><input type="text" class="jh-form" v-model="PROC_MAN" @keydown.enter="selectbutton"></td>
                    <th><label>업무유형</label></th>
                    <td><v-select class="jh-form" :items="SND01_COMBO" item-text="CD_NM" item-value="CD" v-model="CSLT_TY_MDCLS"></v-select></td>
                    <th><label>처리상태</label></th>
                    <td><v-select class="jh-form" :items="STAT_CD_COMBO" item-text="CD_NM" item-value="CD" v-model="PROC_STAT_CD"></v-select></td>
                    <td class="has-search"><v-btn class="jh-btn is-search" @click="selectbutton">조회</v-btn></td>
                </tr>
            </table>
        </div>
        <!--//조회-->

        <!--리스트-->
        <div class="jh-form-wrap">
            <div class="jh-ui-header">
                <h2>2차상담 목록</h2>
                <div class="is-right">
                    <v-btn class="jh-btn is-main" @click="showDialog('P10180101')">배분</v-btn>
                    <v-btn class="jh-btn is-del" @click="redistConfirm()">배분회수</v-btn>
                    <span class="jh-split"></span>
                    <v-btn class="jh-btn is-light" @click="excelDownload('divGridSecondList', '2차상담 관리 '+TODAY)">엑셀다운로드</v-btn>
                </div>
            </div>

            <data-tables
                ref="dataTable"
                id="divGridSecondList"
                dataTableClass="has-control"
                :data-table-options="dataTableOptions"
                @click:row="onClickRow"
                :paginationOptions="paginationOptions"
            >
            </data-tables>
        </div>
        <!--//리스트-->

        <!--상세-->
        <div class="jh-form-wrap jh-cols">
            <!--접수정보-->
            <div class="is-col-fix" style="width:35%">
                <div class="jh-ui-header">
                    <h2>접수정보</h2>
                    <div class="is-right">
                    </div>
                </div>
                <table class="jh-tbl-detail">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th><label>접수유형</label></th>
                            <td> 
                                <span class="is-value">
                                    {{detailObj.CSLT_TY_LCLS}} {{detailObj.CSLT_TY_MDCLS ? '> ' + detailObj.CSLT_TY_MDCLS : ''}} {{detailObj.CSLT_TY_SCLS ? '> ' + detailObj.CSLT_TY_SCLS : ''}}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th><label>예약일시</label></th>
                            <td> 
                                <span class="is-value">{{detailObj.RESV_DT_TIME}}</span>
                            </td>
                        </tr>
                        <tr>
                            <th><label>예약수신번호</label></th>
                            <td> 
                                <span class="is-value">{{detailObj.RESV_TEL_NO}}</span>
                            </td>
                        </tr>
                        <tr>
                            <th><label>예약메모</label></th>
                            <td class="is-p-0"> 
                                <textarea class="jh-form is-trns" style="height: 120px;" readonly v-model="detailObj.RESV_MEMO"></textarea>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!--//접수정보-->
    
            <!--처리정보-->
            <div>
                <div class="jh-ui-header">
                    <h2>처리정보</h2>
                    <div class="is-right">
                        <v-btn class="jh-btn is-main" @click="saveDetail">저장</v-btn>
                    </div>
                </div>
                <table class="jh-tbl-detail">
                    <colgroup>
                        <col width="100px">
                        <col>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th><label>처리일시</label></th>
                            <td> 
                                <span class="is-value">{{detailObj.PROC_DT_TIME}}</span>
                            </td>
                            <th><label>수정일시</label></th>
                            <td> 
                                <span class="is-value">{{detailObj.MOD_DTTM}}</span>
                            </td>
                        </tr>
                        <tr>
                            <th><label>처리직원</label></th>
                            <td> 
                                <span class="is-value">{{detailObj.PROC_MAN}}</span>
                            </td>
                            <th><label>수정직원</label></th>
                            <td> 
                                <span class="is-value">{{detailObj.MOD_MAN}}</span>
                            </td>
                        </tr>
                        <tr>
                            <th><label>처리상태</label></th>
                            <td> 
                                <v-select class="jh-form" :items="STAT_CD_COMBO" item-text="CD_NM" item-value="CD" placeholder="선택" v-model="detailObj.PROC_STAT_CD"></v-select>
                            </td>
                            <th rowspan="2"><label>수정내용</label></th>
                            <td rowspan="2"><textarea class="jh-form" style="height: 146px;" v-model="detailObj.MOD_MSG"></textarea></td>
                        </tr>
                        <tr>
                            <th><label>처리내용</label></th>
                            <td class="is-p-0"> 
                                <textarea class="jh-form is-trns" style="height: 120px;" readonly v-model="detailObj.PROC_CNTN"></textarea>
                            </td>
                            <!-- <th><label>수정내용</label></th>
                            <td> 
                                <textarea class="jh-form" style="height: 116px;" v-model="detailObj.MOD_MSG"></textarea>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
            <!--//처리정보--> 
        </div>
        <!--상세-->
        <v-dialog max-width="1000" v-model="dialogP10180101">
            <dialog-P10180101
            @hide="hideDialog('P10180101')"
            :param="param"
            :userInfo="userInfo"
            :histNos="histNos"
            @status="setStatus"
            @input="userInfo = $event"
            @selectUser="[hideDialog('P10180101'), setUser()]"
            ></dialog-P10180101>
            <template v-slot:body></template>
        </v-dialog>
    </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";
import dialogP10180101 from "./P10180101.vue";
  
export default {
    name: "MENU_E010302", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    components: {
        dialogP10180101,
        dataTables
    },
    data() {
        return {
            dialogP10180101: false,
            menuStartDate: false,
            menuEndDate: false,
            TODAY: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),   
            from: moment().format("YYYY-MM-DD"),
            to: moment().format("YYYY-MM-DD"),     
            dates: [
                (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            ],
            headers: [
                { text: '접수일시', value: 'RECP_DT_TIME', align: 'center', width: '150px',},
                { text: '계좌번호', value: 'ACCOUNT', align: 'center', width: '150px', },
                { text: '고객명', value: 'CUST_NM', align: 'center', width: '150px', },
                { text: '고객번호', value: 'CUST_NO', align: 'center', width: '100px', },
                { text: '업무유형', value: 'CSLT_TY_MDCLS', align: 'center', },
                { text: '접수직원', value: 'RCPT_MAN', align: 'center', width: '150px', },
                { text: '배당직원', value: 'CSLT_MAN', align: 'center', width: '150px', },
                { text: '배분직원', value: 'DSTR_MAN', align: 'center', width: '150px', },
                { text: '처리상태', value: 'PROC_STAT_CD_NM', align: 'center', width: '100px', },
                { text: '처리직원', value: 'PROC_MAN', align: 'center', width: '150px', },
                { text: '처리일시', value: 'PROC_DT_TIME', align: 'center', width: '150px', },
            ],
            selectedList:[],
            dataTableOptions: {
                fixedHeader: true,
                hideDefaultFooter: true,
                height: '280px',
                items: undefined,
                itemKey: 'ROWNUM',
                itemsPerPage: 50,
                noDataText: "조회된 결과가 없습니다.",
                page: 1,
                showSelect: true,
                itemClass: "[item.selected && 'selected']",
                load: undefined,
            },
            paginationOptions: {
                totalVisible: 10
            },
            alertMsg: {
                saveDetail: '저장하시겠습니까?'
            },
            param: {
                page: 'E010301',
                type: 'secondary'
            },
            histNos: '',
            userInfo: {},

            // 콤보박스
            STAT_CD_COMBO: [{ CD: '', CD_NM: '전체' }],
            SND01_COMBO: [{ CD: '', CD_NM: '전체' }],

            // 조회조건
            ACCOUNT: '', // 계좌번호
            RCPT_MAN: '', // 접수직원
            CUST_NM: '', // 고객명
            CSLT_TY_MDCLS: '', // 업무유형
            CUST_NO: '', // 고객번호
            CSLT_MAN: '', // 배당직원
            PROC_MAN: '', // 처리직원
            PROC_STAT_CD: '', // 처리상태

            // 접수정보, 처리정보
            detailObj: {
                CSLT_TY_LCLS: '',         // 접수유형(대)
                CSLT_TY_MDCLS: '',        // 접수유형(중)
                CSLT_TY_SCLS: '',         // 접수유형(소)
                RESV_DT_TIME: '',         // 예약일시
                RESV_TEL_NO: '',          // 예약수신번호
                RESV_MEMO: '',            // 예약메모
                PROC_DT_TIME: '',  // 처리일시
                PROC_MAN: '',      // 처리직원
                CNSL_CNTN: '',            // 처리내용
                MOD_MAN: '',              // 수정직원
                MOD_MSG: '',              // 수정내용
            },

            CSLT_TY_CNTN: '',

            status: '',

            selectedRow: [],
            message: '',
        }
    },
    computed: {
        rowNum() {
            return this.items?.map(
                (items, index) => ({
                ...items,
                index: index + 1
            }))
        },
        dataTableInstance: function() {
            return this.$refs.dataTable;
        },     
        initHeaders(){
            return {
                SERVICE: "phone.secondary",
                METHOD: "",
                TYPE: 'BIZ_SERVICE',
            };
        },
    },
    created() { 
        this.dataTableOptions.headers = this.headers;
        this.dataTableOptions.items = this.rowNum;
    },
    mounted() {
        this.getSelBoxList();
    },
    watch: {
        "status": async function (val) {
            if (val === 'end') {
                this.dataTableInstance.loadData();
                this.detailObj = {};
                this.status = '';
            }
        }
    },
    methods: {
        fromOnInput() {
            // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
            if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
                // 종료일자보다 이후를 입력했으면 종료일자로 변경
                if (moment(this.from).isAfter(this.dates[1])) {
                    this.$nextTick(() => {
                        this.from = this.dates[1];
                    });
                }

                this.$nextTick(() => {
                    this.dates[0] = this.from;
                    // datepick 다시 랜더링
                    this.menuStartDate = false;
                    this.menuStartDate = true;
                });
            }
        },
        changeDate(type) {
            if(type == 'start') {
                if (moment(this.dates[0]).isBefore(moment(this.dates[1]).subtract(90, 'days').format('YYYY-MM-DD'))) {
                    this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
                    const date = moment().format("YYYY-MM-DD");
                    this.dates[0] = date;
                    this.from = date;
                    return;
                } else {
                    this.from = this.dates[0];
                }
            } else if (type == 'end') {
                if (moment(this.dates[1]).isAfter(moment(this.dates[0]).add(90, 'days').format('YYYY-MM-DD'))) {
                    this.common_alert('검색 기간은 최대 3개월까지 선택 가능합니다.');
                    const date = moment().format("YYYY-MM-DD");
                    this.dates[1] = date;
                    this.to = date;
                    return;
                } else {
                    this.to = this.dates[1];
                }
            }
        },
        startDate(e) {
            this.from = e;
        },
        toOnInput() {
            // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
            if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
                // 시작일자보다 이전을 입력했으면 시작일자로 변경
                if (moment(this.to).isBefore(this.dates[0])) {
                    this.$nextTick(() => {
                        this.to = this.dates[0];
                    });
                }

                this.$nextTick(() => {
                    this.dates[1] = this.to;
                    // datepick 다시 랜더링
                    this.menuEndDate = false;
                    this.menuEndDate = true;
                });
            }
        },
        endDate(e) {
            this.to = e;
        },
        onClickRow(item, row) {
            if (item != null) {
                // row.select(true);
                // row.isSelected = true;
                this.detailObj = item;
                this.selectedRow.push(row);
            }
        },
        showDialog(type) {
            //팝업 파라미터 설정
            this.popupParams;
            if (this.validDist()) {
                this.histNos = this.getCnslHistNos();
                this[`dialog${type}`] = true;
            } else {
                this.common_alert(this.message, 'noti');
            }
        },
        hideDialog(type) {
            this[`dialog${type}`] = false;
        },
        submitDialog(type){
            console.log('완료');
            this[`dialog${type}`] = false
        },
        selectbutton() {
            this.detailObj = {};
            this.dataTableOptions.load = this.selectSecondaryWork;

            if (this.dataTableOptions.page > 1) {
                this.dataTableOptions.page = 1;
            } else {
                this.dataTableInstance.loadData();
            }
        },
        async selectSecondaryWork(loadOptions) {

            // if (this.selectedRow.length > 0) {
            //     this.selectedRow.forEach(item => {
            //         item.select(false);
            //     });
            // }

            let requestData = {
                headers: {
                    SERVICE: this.initHeaders.SERVICE,
                    METHOD: this.initHeaders.METHOD,
                    TYPE: this.initHeaders.TYPE,
                },
                sendData: {}
            };

            const sortBy = loadOptions?.sortBy?.[0];
            const sortDesc = loadOptions?.sortDesc?.[0] === undefined ? 'desc' : loadOptions?.sortDesc?.[0] ? 'desc' : 'asc';

            requestData.headers["URL"] = "/api/phone/secondary/list";
            requestData.headers["METHOD"] = "select";
            requestData.headers["ASYNC"] = false;
            requestData.headers["ROW_CNT"] = loadOptions.itemsPerPage;
            requestData.headers["PAGES_CNT"] = loadOptions.page; 

            requestData.sendData["STR_RECP_DT"] = this.dates[0].replace(/\-/g, "");
            requestData.sendData["END_RECP_DT"] = this.dates[1].replace(/\-/g, "");
            requestData.sendData["ACCOUNT"] = this.ACCOUNT;
            requestData.sendData["RCPT_MAN"] = this.RCPT_MAN;
            requestData.sendData["CUST_NM"] = this.CUST_NM;
            requestData.sendData["CUST_NO"] = this.CUST_NO;
            requestData.sendData["CSLT_MAN"] = this.CSLT_MAN;
            requestData.sendData["CSLT_TY_MDCLS"] = this.CSLT_TY_MDCLS;
            requestData.sendData["PROC_MAN"] = this.PROC_MAN;
            requestData.sendData["PROC_STAT_CD"] = this.PROC_STAT_CD;
            requestData.sendData["SORT_ORDR"] = sortBy ? sortBy : 'ROWNUM';
            requestData.sendData["SORT_DRCT"] = sortDesc;

            const response = await this.common_postCall(requestData);

            if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
                let header = response.HEADER;
                let data = response.DATA;

                data.forEach(item => {
                    item.ACCOUNT = item.ACCOUNT.indexOf('-') < 0 ? 
                        this.mixin_accNoFormat(item.ACCOUNT) : this.mixin_accNoFormat(item.ACCOUNT.replace(/\-/g, ''));
                    item.RESV_TEL_NO = item.RESV_TEL_NO.indexOf('-') < 0 ?
                        this.mixin_telNumFormat(item.RESV_TEL_NO) : item.RESV_TEL_NO;
                });

                return {
                    data: data,
                    totalCount: header.TOT_COUNT
                };
            }
        },
        excelDownload(table_id, file_name) {
            //number,date,time,string - n,d,t,s
            var exceltypeArr = {};
            exceltypeArr[0] = "n"; 
            exceltypeArr[1] = "s"; 
            exceltypeArr[2] = "s"; 
            exceltypeArr[3] = "s"; 
            exceltypeArr[4] = "s"; 
            exceltypeArr[5] = "s"; 
            exceltypeArr[6] = "s"; 
            exceltypeArr[7] = "s"; 
            exceltypeArr[8] = "s"; 
            exceltypeArr[9] = "s"; 
            exceltypeArr[10] = "s"; 
            exceltypeArr[11] = "s"; 
            exceltypeArr[12] = "s"; 

            this.table_id = table_id;
            this.file_name = file_name;
            this.mixin_common_exportExcel(exceltypeArr);
        },

        getCnslHistNos() {
            let histNos = "";

            let obj = JSON.stringify(this.dataTableInstance.getSelectedData());
            let obj2 = JSON.parse(obj);

            const arr = [];

            Object.keys(obj2).map(item => {
                if (obj2[item].PROC_STAT_CD != '02' && obj[item].PROC_STAT_CD != '03') {
                    arr.push(obj2[item].CNSL_HIST_NO);
                }
            })

            for(let i in arr) {
                histNos += arr[i] + "/";
            }

            return histNos;
        },
        redistConfirm() {
            if (this.validDist('회수')) {
                this.common_confirm('배당직원을 배분회수 하시겠습니까?', this.updateRedist, null, null, null, "noti");
            } else {
                this.common_alert(this.message, 'noti');
            }
        },
        async updateRedist() {

            let requestData = {
                headers: {
                    SERVICE: this.initHeaders.SERVICE,
                    METHOD: this.initHeaders.METHOD,
                    TYPE: this.initHeaders.TYPE,
                },
                sendData: {}
            };

            let histNos = this.getCnslHistNos();

            requestData.headers["URL"] = "/api/phone/secondary/up-redist";
            requestData.headers["METHOD"] = "update";
            requestData.headers["ASYNC"] = false;

            requestData.sendData["CNSL_HIST_NO"] = histNos;
            requestData.sendData["CSLT_MAN"] = '';  // 담당자
            requestData.sendData["DSTR_MAN"] = this.$store.getters["userStore/GE_USER_ROLE"].userId;
            requestData.sendData["MOD_MAN"] = this.$store.getters["userStore/GE_USER_ROLE"].userId;
            requestData.sendData["UPD_ID"] = this.$store.getters["userStore/GE_USER_ROLE"].userId;

            const response = await this.common_postCall(requestData);

            if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
                this.common_alert(`정상적으로 배분회수되었습니다.`, 'done')
                this.dataTableInstance.loadData();
            }
        },

        saveDetail() {
            this.common_confirm(this.alertMsg.saveDetail, this.saveConfirm, null, null, null, "error");
        }, 

        async saveConfirm() {
            let requestData = {
                headers: {
                    SERVICE: this.initHeaders.SERVICE,
                    METHOD: this.initHeaders.METHOD,
                    TYPE: this.initHeaders.TYPE,
                },
                sendData: {}
            };

            requestData.headers["URL"] = "/api/phone/secondary/up-detail";
            requestData.headers["METHOD"] = "update";
            requestData.headers["ASYNC"] = false;

            requestData.sendData["PROC_STAT_CD"] = this.detailObj.PROC_STAT_CD;
            requestData.sendData["MOD_MSG"] = this.detailObj.MOD_MSG;
            requestData.sendData["MOD_MAN"] = this.$store.getters["userStore/GE_USER_ROLE"].userId;
            requestData.sendData["CNSL_HIST_NO"] = this.detailObj.CNSL_HIST_NO;
            requestData.sendData["UPD_ID"] = this.$store.getters["userStore/GE_USER_ROLE"].userId;

            const response = await this.common_postCall(requestData);

            if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
                this.common_alert(`정상적으로 수정되었습니다.`, 'done')
                this.dataTableInstance.loadData();
            }
        },
        async getSelBoxList() {
            let requestData = {
                headers: {
                    SERVICE: this.initHeaders.SERVICE,
                    METHOD: this.initHeaders.METHOD,
                    TYPE: this.initHeaders.TYPE,
                },
                sendData: {}
            };

            requestData.headers["URL"] = "/api/code/common/code-book/selectCode";
            requestData.headers["METHOD"] = "selectCode";
            requestData.headers["ASYNC"] = false;

            let sendList = [];
            sendList.push("SND01");     //2차상담업무유형코드
            sendList.push('2NDSTS');    //처리상태

            requestData.sendData["GROUP_CD"] = sendList

            const response = await this.common_postCall(requestData);

            if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {

                for (var i = 0; i < response.DATA.length ; i++){
                    if (response.DATA[i].GROUP_CD === "SND01"){
                        this.SND01_COMBO.push(response.DATA[i]);
                    } else if (response.DATA[i].GROUP_CD === '2NDSTS') {
                        this.STAT_CD_COMBO.push(response.DATA[i]);
                    }
                }
            }
        },
        validDist(param) {
            let result = true;

            let obj = JSON.stringify(this.dataTableInstance.getSelectedData());
            let obj2 = JSON.parse(obj);

            if (Object.keys(obj2).length > 0) {                
                Object.keys(obj2).map(item => {
                    if (obj2[item].PROC_STAT_CD == '02' || obj2[item].PROC_STAT_CD == '03') {
                        result = false;
                        if(param == undefined) param = '';
                        this.message = '처리상태가 처리중 또는 처리완료인 상담은<br/>배분'+param+' 할 수 없습니다.';
                    }
                })
            } else {
                result = false;
                this.message = '선택된 항목이 없습니다.';
            }

            return result;
        },
        setStatus() {
            this.status = 'end';
        }
    },
    
};
</script>

<style></style>  